// assets
import { DashboardOutlined } from "@ant-design/icons";

import HouseOutlinedIcon from "@mui/icons-material/HouseOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import ListAltIcon from "@mui/icons-material/ListAlt";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";

import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import Category from "@mui/icons-material/Category";
import { CalendarMonth } from "../../node_modules/@mui/icons-material/index";

// icons
const icons = {
  DashboardOutlined,
  HouseOutlinedIcon,
  CalendarMonthOutlinedIcon,
  PaidOutlinedIcon,
  RequestQuoteOutlinedIcon,
  NoteAltOutlinedIcon,
  ListAltIcon,
  Category,
  CalendarMonth,
};

const financeiro = {
  id: "financeiro",
  title: "Financeiro",
  type: "group",
  children: [
    {
      id: "competencia",
      title: "Competência",
      type: "item",
      url: "/competencia",
      icon: icons.CalendarMonth,
      breadcrumbs: false,
      roles: ["Admin"],
    },
    {
      id: "contasPagar",
      title: "Contas a Pagar",
      type: "item",
      url: "/contasPagar",
      icon: icons.PaidOutlinedIcon,
      breadcrumbs: false,
      roles: ["Admin", "Contabilidade"],
    },

    {
      id: "contasReceber",
      title: "Contas a Receber",
      type: "item",
      url: "/contasReceber",
      icon: icons.RequestQuoteOutlinedIcon,
      breadcrumbs: false,
      roles: ["Admin"],
    },
    {
      id: "CategoriaContasPagar",
      title: "Categoria Contas a Pagar",
      type: "item",
      url: "/categoriaContasPagar",
      icon: icons.Category,
      breadcrumbs: false,
      roles: ["Admin"],
    },
    {
      id: "CategoriaContasPagarItem",
      title: "Categoria Contas a Pagar item",
      type: "item",
      url: "/categoriaContasPagarItem",
      icon: icons.Category,
      breadcrumbs: false,
      roles: ["Admin"],
    },

    {
      id: "CategoriaContasReceber",
      title: "Categoria Contas a Receber",
      type: "item",
      url: "/categoriaContasReceber",
      icon: icons.Category,
      breadcrumbs: false,
      roles: ["Admin"],
    },
  ],
};

export default financeiro;
