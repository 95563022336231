// material-ui
import { Box, Typography } from "@mui/material";
import jwt_decode from "jwt-decode";

// project import
import {
  menuItemsAdmin,
  menuItemsTecnico,
  menuItemsGerencial,
  menuItemsContabilidade,
} from "menu-items";
import NavGroup from "./NavGroup";

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const token = sessionStorage.getItem("token");
  const decoded = token ? jwt_decode(token).Role : "";

  let menu;
  switch (decoded) {
    case "Tecnico":
      menu = menuItemsTecnico;
      break;
    case "Gerente":
      menu = menuItemsGerencial;
      break;
    case "Admin":
      menu = menuItemsAdmin;
      break;
    case "Contabilidade":
      menu = menuItemsContabilidade;
      break;
    default:
      menu = menuItemsTecnico;
      break;
  }

  const navGroups = menu.items.map((item) => {
    switch (item.type) {
      case "group":
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
