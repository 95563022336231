import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import PersonPinOutlinedIcon from "@mui/icons-material/PersonPinOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
const icons = {
  AccountCircleOutlinedIcon,
  GroupsOutlinedIcon,
  ManageAccountsOutlinedIcon,
  PersonPinOutlinedIcon,
  SettingsIcon,
};

const workers = {
  id: "pessoas",
  title: "Pessoas",
  type: "group",
  children: [
    {
      id: "Gestor",
      title: "Gestor",
      type: "item",
      url: "/gestor",
      icon: icons.GroupsOutlinedIcon,
      breadcrumbs: false,
    },
    {
      id: "Funcionario",
      title: "Funcionário",
      type: "item",
      url: "/Funcionario",
      icon: icons.ManageAccountsOutlinedIcon,
      breadcrumbs: false,
    },
    {
      id: "Cliente",
      title: "Cliente",
      type: "item",
      url: "/Cliente",
      icon: icons.PersonPinOutlinedIcon,
      breadcrumbs: false,
    },
  ],
};

export default workers;
