import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import PersonPinOutlinedIcon from "@mui/icons-material/PersonPinOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
const icons = {
  AccountCircleOutlinedIcon,
  GroupsOutlinedIcon,
  ManageAccountsOutlinedIcon,
  PersonPinOutlinedIcon,
  SettingsIcon,
};

const sistema = {
  id: "sistema",
  title: "Sistema",
  type: "group",
  children: [
    {
      id: "Configurações",
      title: "Configurações",
      type: "item",
      url: "/configuracao",
      icon: icons.SettingsIcon,
      breadcrumbs: false,
    },
  ],
};

export default sistema;
