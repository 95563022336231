import React from "react";
import { useState, useEffect } from "react";
import { useApi } from "hooks/useApi";
import { useParams, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  Paper,
  Modal,
  Switch,

} from "@mui/material";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";

import * as Yup from "yup";

import Loader from "components/Loader";
import ModalSucessMessage from "components/modalSucess";
import { FormControl } from "../../../node_modules/@mui/material/index";
import moment from "../../../node_modules/moment/moment";

const CriarCompetencia = () => {
  //regex para campos de número
  const onlyNumbers = /^\d+$/;

  //interação com a api
  const api = useApi();
  const { id } = useParams();
  const [Competencia, setCompetencia] = useState({
    competenciaCodigo: "",
  });

  const navigate = useNavigate();
  const getData = async () => {
    const response = await api.getCompetenciaById(id);

    const info = { ...response.data };
    setCompetencia(info);
  };
  // Loader
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (id) {
      getData();
    }
  }, []);

  // Password show
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  // Modal
  const [open, setOpen] = useState(false);
  const handleClose = () => navigate(`/competencia/`);
  const handleOpen = () => setOpen(true);

  return (
    <>
      <ModalSucessMessage open={open} handleClose={handleClose} />
      {isLoading ? <Loader /> : null}

      <Grid container alignItems="center" justifyContent="space-between">
        <Typography variant="h4">Competência Cadastro</Typography>
        <Button
          onClick={() => {
            navigate(`/competencia/`);
          }}
        >
          <Fab size="small" color="primary" variant="extended">
            <ArrowBackIcon />
            Voltar
          </Fab>
        </Button>
      </Grid>

      <Formik
        validationSchema={Yup.object().shape({
          competenciaCodigo: Yup.string().max(255).required("Código é obrigatório"),
          competenciaVencimento: Yup.date().required("Data de vencimento é obrigatória").typeError("Por favor, insira uma data válida"),
        })}
        enableReinitialize={true}
        initialValues={{ ...Competencia, competenciaVencimento: moment(Competencia.competenciaVencimento) }}
        onSubmit={async (values, { setSubmitting }) => {
          setIsLoading(true);
          try {
            if (id) {
              const response = await api.putCompetencia({
                competenciaId: values.competenciaId,
                competenciaCodigo: values.competenciaCodigo,
                competenciaVencimento: values.competenciaVencimento,
                competenciaStatus: values.competenciaStatus
              }
              );
              setIsLoading(false);
              handleOpen();
              return;
            }

            const response = await api.postCompetencia({
              competenciaCodigo: values.competenciaCodigo,
              competenciaVencimento: values.competenciaVencimento,
              competenciaStatus: values.competenciaStatus
            });
            setIsLoading(false);
            handleOpen();
          } catch (err) {
            setIsLoading(false);
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <Paper style={{ padding: "30px" }}>
            <form noValidate onSubmit={handleSubmit}>
              <Grid
                alignItems="center"
                container
                rowSpacing={4}
                columnSpacing={10}
              >
                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth >
                    <InputLabel htmlFor="codigo">Código</InputLabel>
                    <OutlinedInput
                      id="codigo"
                      placeholder="Código"
                      value={values.competenciaCodigo}
                      name="competenciaCodigo"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                    />
                    <FormHelperText
                      error
                      sx={{
                        opacity:
                          touched.competenciaCodigo &&
                            errors.competenciaCodigo
                            ? 1
                            : 0,
                      }}
                    >
                      {errors.competenciaCodigo}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>

                    <Field name="competenciaVencimento">
                      {({ field }) => (
                        <DatePicker
                          {...field}
                          id="competenciaVencimento"
                          openTo="day"
                          label="Vencimento"
                          value={values.competenciaVencimento || null}
                          onChange={(date) => {
                            handleChange({
                              target: {
                                name: field.name,
                                value: date._d,
                              },
                            });
                          }}
                        />
                      )}
                    </Field>
                    <FormHelperText
                      error
                      sx={{
                        opacity:
                          errors.competenciaVencimento
                            ? 1
                            : 0,
                      }}
                    >

                      {errors.competenciaVencimento}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <FormControlLabel
                      control={<Switch

                        checked={values.competenciaStatus || false}
                        onChange={(e) => handleChange({
                          target: {
                            name: "competenciaStatus",
                            value: e.target.checked,
                          },
                        })} />}
                      label="Ativo"
                    />

                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: 2,
                  }}
                >
                  <Button type="submit" disabled={isSubmitting}>
                    <Fab size="small" color="primary" variant="extended">
                      <CheckOutlinedIcon />
                      Salvar
                    </Fab>
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        )}
      </Formik>
    </>
  );
};

export default CriarCompetencia;
