// action - account reducer
export const LOGIN = "@auth/LOGIN";
export const LOGOUT = "@auth/LOGOUT";
export const REGISTER = "@auth/REGISTER";

export function addConfig(payload) {
  return {
    type: "ADD_CONFIG",
    payload,
  };
}

export function clearConfig() {
  return {
    type: "CLEAR_CONFIG",
  };
}
