import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Stack } from '../../node_modules/@mui/material/index';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center'
};

export default function ModalDeleteMessage({ open, closeDelete, user, confirmDelete }) {
    return (
        <div>
            {/* <Button onClick={handleOpen}>Open modal</Button> */}
            <Modal open={open} onClose={closeDelete} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <>
                    <Box sx={style}>
                        <Typography id="modal-modal-description" fontSize="1.1rem">
                            Deletar {user} ?
                        </Typography>
                        <Stack direction="row" mt="15px" gap="30px">
                            <Button
                                color="error"
                                onClick={() => {
                                    confirmDelete();
                                }}
                            >
                                Sim
                            </Button>
                            <Button
                                onClick={() => {
                                    closeDelete();
                                }}
                            >
                                Não
                            </Button>
                        </Stack>
                    </Box>
                </>
            </Modal>
        </div>
    );
}
