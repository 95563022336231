import React from "react";
import { useState } from "react";
import { useApi } from "hooks/useApi";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import Loader from "components/Loader";
import ModalDeleteMessage from "components/DeleteConfirmationModal";
import { Link } from "@mui/material";
import { Grid, Switch } from "../../../node_modules/@mui/material/index";
import Typography from "@mui/material/Typography";
import LoadingCircle from "layout/Loading/Loading";
import ModalSucessMessage from "components/modalSucess";
import { useSelector } from "../../../node_modules/react-redux/es/exports";
import { dispatch } from "store/index";
import { addConfig } from "store/reducers/actions";

const Config = () => {
  const configuracoes = useSelector((state) => state.configuration);

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const api = useApi();

  //formatar data

  //Modal delete
  const [open, setOpenDelete] = useState(false);
  const closeDelete = () => setOpenDelete(false);
  const openDelete = (clienteId) => setOpenDelete(clienteId);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const confirmDelete = (clienteId) => {
    api.deleteCliente(clienteId).then(() => {
      closeDelete();
      setShowSuccessModal(true);
      getData();
    });
  };

  const getConfig = async () => {
    const res = await api.getConfiguration();
    const info = res?.data ?? [];
    dispatch(addConfig(info));
  };

  const handleToggle = async (value, config) => {
    const dataToSend = { ...config };
    let param = JSON.parse(dataToSend.configuracaoParametro);
    param.Habilitado = value;
    dataToSend.configuracaoParametro = JSON.stringify(param);
    const response = await api.updateConfiguration(dataToSend);
    if (!response) return;
    getConfig();
  };

  return (
    <>
      {isLoading ? <Loader /> : null}
      <Grid container alignItems="center" justifyContent="space-between">
        <Typography variant="h4">Configurações</Typography>

        {/* <Button
          onClick={() => {
            navigate(`/configuracao/criar`);
          }}
        >
          <Fab size="small" color="primary" variant="extended">
            <AddIcon sx={{ mr: 0.5 }} />
            Novo
          </Fab>
        </Button> */}
      </Grid>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 150 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">ID</TableCell>
              <TableCell align="left">Nome</TableCell>
              <TableCell align="center" style={{ width: "15%" }}>
                Ativo
              </TableCell>
              {/* <TableCell align="center" style={{ width: "15%" }}>
                Ações
              </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {configuracoes.map((config) => {
              let ativo = false;
              if (config.configuracaoParametro) {
                const params = JSON.parse(config.configuracaoParametro);
                console.log("params: ", params);
                ativo = params.Habilitado;
              }
              return (
                <>
                  <ModalDeleteMessage
                    open={open === config.configuracaoId}
                    confirmDelete={() => confirmDelete(config.configuracaoId)}
                    closeDelete={closeDelete}
                    user={config.clienteNome}
                  />
                  <TableRow
                    hover
                    key={config.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">
                      <Link
                        color="secondary"
                        onClick={() => {
                          navigate(
                            `/configuracao/criar/${config.configuracaoId}`
                          );
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {config.configuracaoId}
                      </Link>
                    </TableCell>
                    <TableCell align="left">
                      {config.configuracaoNome}
                    </TableCell>
                    <TableCell align="center">
                      <Switch
                        checked={ativo}
                        onChange={(e) => {
                          handleToggle(e.target.checked, config);
                        }}
                      ></Switch>
                    </TableCell>
                    {/* <TableCell align="center" style={{ width: "15%" }}>
                      <Tooltip title="Editar">
                        <Button
                          onClick={() => {
                            navigate(
                              `/configuracao/criar/${config.configuracaoId}`
                            );
                          }}
                        >
                          <EditOutlinedIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Deletar">
                        <Button
                          onClick={async () => {
                            openDelete(config.configuracaoId);
                          }}
                        >
                          <DeleteOutlineOutlinedIcon />
                        </Button>
                      </Tooltip>
                    </TableCell> */}
                  </TableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {isLoading ? <LoadingCircle /> : null}
      {showSuccessModal && (
        <ModalSucessMessage
          open={showSuccessModal}
          handleClose={() => setShowSuccessModal(false)}
        />
      )}
    </>
  );
};

export default Config;
