// project import
import Routes from "routes";
import ThemeCustomization from "themes";
import ScrollTop from "components/ScrollTop";
import "Index.css";
import Notification from "components/toast/Toast";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/en-gb";
import { RequireAuth } from "components/Auth";
import { useCallback, useEffect } from "react";
import { useApi } from "hooks/useApi";
import { dispatch } from "store/index";
import { addConfig } from "store/reducers/actions";
import _ from "lodash";

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  const api = useApi();

  const getConfig = async () => {
    const res = await api.getConfiguration();
    const info = res?.data ?? [];
    dispatch(addConfig(info));
  };

  const debounceToastError = useCallback(
    _.debounce(() => {
      getConfig();
    }, 100),
    []
  );

  useEffect(() => {
    if (!sessionStorage.getItem("token")) return;
    debounceToastError();
  }, []);

  return (
    <>
      <ThemeCustomization>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
          <ScrollTop>
            <RequireAuth>
              <Routes />
            </RequireAuth>
          </ScrollTop>
          <Notification />
        </LocalizationProvider>
      </ThemeCustomization>
    </>
  );
};

export default App;
