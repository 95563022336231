import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import MainLayout from "layout/MainLayout";
import Config from "pages/config/index";
import CriarConfig from "pages/config/criarConfig";
import CriarCompetencia from "pages/competencia/criarCompetencia";

//Tela inicial
const Inicio = Loadable(lazy(() => import("pages/inicio")));

//Unauthorized
const UnauthorizedPage = Loadable(
  lazy(() => import("../components/Unauthorized"))
);

//Funcionario
const Funcionario = Loadable(lazy(() => import("pages/funcionario")));
const CriarFuncionario = Loadable(
  lazy(() => import("pages/funcionario/criarFuncionario"))
);

//Cliente
const Cliente = Loadable(lazy(() => import("pages/cliente")));
const CriarCliente = Loadable(lazy(() => import("pages/cliente/criarCliente")));

//Categoria Contas Pagar
const CategoriaContasPagar = Loadable(
  lazy(() => import("pages/categoriaContasPagar"))
);
const CategoriaContasPagarItem = Loadable(
  lazy(() => import("pages/categoriaContasPagarItem"))
);
const Competencia = Loadable(lazy(() => import("pages/competencia")));
const CriarCategoriaContasPagar = Loadable(
  lazy(() => import("pages/categoriaContasPagar/criarCategoriaContasPagar"))
);
const CriarCategoriaContasPagarItem = Loadable(
  lazy(() =>
    import("pages/categoriaContasPagarItem/criarCategoriaContasPagarItem")
  )
);
//Categoria Contas Receber
const CategoriaContasReceber = Loadable(
  lazy(() => import("pages/categoriaContasReceber"))
);
const CriarCategoriaContasReceber = Loadable(
  lazy(() => import("pages/categoriaContasReceber/criarCategoriaContasReceber"))
);

//Gestor
const Gestor = Loadable(lazy(() => import("pages/gestor")));
const CriarGestor = Loadable(lazy(() => import("pages/gestor/criarGestor")));

// Projeto
const Projeto = Loadable(lazy(() => import("pages/projeto")));
const CriarProjeto = Loadable(lazy(() => import("pages/projeto/criarProjeto")));

//Lancamento
const Lancamento = Loadable(lazy(() => import("pages/lancamento")));

//Relatorio
const Relatorio = Loadable(lazy(() => import("pages/relatorio")));
//Lancamento
const Fechamento = Loadable(lazy(() => import("pages/fechamento")));

//Aprovação lançamento
const AprovacaoLancamentos = Loadable(
  lazy(() => import("pages/aprovacaoLancamentos"))
);

const ContasPagar = Loadable(lazy(() => import("pages/contasPagar")));

//Atualizar senha
const Attsenha = Loadable(lazy(() => import("pages/perfil/atualizarSenha")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <Inicio />,
      roles: ["Admin", "Gerente"],
    },
    {
      path: "/unauthorized",
      element: <UnauthorizedPage />,
      roles: ["Tecnico"],
    },
    {
      path: "Funcionario",
      element: <Funcionario />,
      roles: ["Admin", "Gerente"],
    },
    {
      path: "funcionario",
      element: <Funcionario />,
      roles: ["Admin", "Gerente"],
    },
    {
      path: "funcionario/",
      element: <Funcionario />,
      roles: ["Admin", "Gerente"],
    },
    {
      path: "Funcionario/",
      element: <Funcionario />,
      roles: ["Admin", "Gerente"],
    },
    {
      path: "funcionario/criar/:id",
      element: <CriarFuncionario />,
      roles: ["Admin", "Gerente"],
    },
    {
      path: "funcionario/criar",
      element: <CriarFuncionario />,
      roles: ["Admin", "Gerente"],
    },
    {
      path: "funcionario/criar/",
      element: <CriarFuncionario />,
      roles: ["Admin", "Gerente"],
    },
    {
      path: "cliente",
      element: <Cliente />,
      roles: ["Admin", "Gerente"],
    },
    {
      path: "configuracao",
      element: <Config />,
      roles: ["Admin", "Gerente"],
    },
    {
      path: "configuracao/",
      element: <Config />,
      roles: ["Admin", "Gerente"],
    },
    {
      path: "configuracao/criar/",
      element: <CriarConfig />,
      roles: ["Admin", "Gerente"],
    },
    {
      path: "configuracao/criar/:id",
      element: <CriarConfig />,
      roles: ["Admin", "Gerente"],
    },
    {
      path: "cliente/",
      element: <Cliente />,
      roles: ["Admin", "Gerente"],
    },
    {
      path: "cliente/criar",
      element: <CriarCliente />,
      roles: ["Admin", "Gerente"],
    },
    {
      path: "cliente/criar/",
      element: <CriarCliente />,
      roles: ["Admin", "Gerente"],
    },
    {
      path: "cliente/criar/:id",
      element: <CriarCliente />,
      roles: ["Admin", "Gerente"],
    },
    {
      path: "categoriaContasPagar/",
      element: <CategoriaContasPagar />,
      roles: ["Admin", "Gerente"],
    },
    {
      path: "categoriacontaspagar/criar/:id",
      element: <CriarCategoriaContasPagar />,
      roles: ["Admin", "Gerente"],
    },
    {
      path: "categoriacontaspagar/criar/",
      element: <CriarCategoriaContasPagar />,
      roles: ["Admin", "Gerente"],
    },

    {
      path: "categoriaContasPagarItem/",
      element: <CategoriaContasPagarItem />,
      roles: ["Admin", "Gerente"],
    },
    {
      path: "categoriacontaspagaritem/criar/:id",
      element: <CriarCategoriaContasPagarItem />,
      roles: ["Admin", "Gerente"],
    },
    {
      path: "categoriacontaspagaritem/criar/",
      element: <CriarCategoriaContasPagarItem />,
      roles: ["Admin", "Gerente"],
    },
    {
      path: "categoriaContasReceber",
      element: <CategoriaContasReceber />,
      roles: ["Admin", "Gerente"],
    },
    {
      path: "categoriaContasReceber/",
      element: <CategoriaContasReceber />,
      roles: ["Admin", "Gerente"],
    },
    {
      path: "categoriacontasreceber/criar/:id",
      element: <CriarCategoriaContasReceber />,
      roles: ["Admin", "Gerente"],
    },
    {
      path: "categoriacontasreceber/criar",
      element: <CriarCategoriaContasReceber />,
      roles: ["Admin", "Gerente"],
    },
    {
      path: "cliente/criar/:id",
      element: <CriarCliente />,
      roles: ["Admin", "Gerente"],
    },
    {
      path: "cliente/criar/",
      element: <CriarCliente />,
      roles: ["Admin", "Gerente"],
    },
    {
      path: "gestor",
      element: <Gestor />,
      roles: ["Admin", "Gerente"],
    },
    {
      path: "gestor/",
      element: <Gestor />,
      roles: ["Admin", "Gerente"],
    },
    {
      path: "gestor/criar",
      element: <CriarGestor />,
      roles: ["Admin", "Gerente"],
    },
    {
      path: "gestor/criar/:id",
      element: <CriarGestor />,
      roles: ["Admin", "Gerente"],
    },
    {
      path: "projetos",
      element: <Projeto />,
      roles: ["Admin", "Gerente"],
    },
    {
      path: "projetos/",
      element: <Projeto />,
      roles: ["Admin", "Gerente"],
    },
    {
      path: "projetos/criar",
      element: <CriarProjeto />,
      roles: ["Admin", "Gerente"],
    },
    {
      path: "projetos/criar/:id",
      element: <CriarProjeto />,
      roles: ["Admin", "Gerente"],
    },
    {
      path: "lancamento",
      element: <Lancamento />,
      roles: ["Tecnico", "Admin", "Gerente"],
    },
    {
      path: "relatorio",
      element: <Relatorio />,
      roles: ["Tecnico", "Admin", "Gerente"],
    },
    {
      path: "fechamento",
      element: <Fechamento />,
      roles: ["Tecnico", "Admin", "Gerente"],
    },
    {
      path: "aprovacaoLancamentos",
      element: <AprovacaoLancamentos />,
      roles: ["Admin", "Gerente"],
    },
    {
      path: "contasPagar",
      element: <ContasPagar />,
      roles: ["Admin", "Contabilidade"],
    },
    {
      path: "competencia",
      element: <Competencia />,
      roles: ["Admin"],
    },
    {
      path: "competencia/criar",
      element: <CriarCompetencia />,
      roles: ["Admin"],
    },
    {
      path: "competencia/criar/:id",
      element: <CriarCompetencia />,
      roles: ["Admin"],
    },
    {
      path: "atualizarSenha",
      element: <Attsenha />,
    },
  ],
};

export default MainRoutes;
