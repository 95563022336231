import dashboard from "./dashboard";
import workers from "./pessoas";
import dashboardTecnico from "./dashboardTecnico";
import sistema from "./sistema";
import gerencial from "./gerencial";
import financeiro from "./financeiro";

// Função para filtrar itens do menu por role
function filterMenuByRole(menuGroup, role) {
  return {
    ...menuGroup,
    children: menuGroup.children.filter((item) => item.roles.includes(role)),
  };
}
const filteredFinanceiro = filterMenuByRole(financeiro, "Contabilidade");

// ==============================|| MENU ITEMS ||============================== //

const menuItemsAdmin = {
  items: [dashboard, gerencial, financeiro, workers, sistema],
};
const menuItemsGerencial = {
  items: [dashboard, gerencial, workers, sistema],
};
const menuItemsTecnico = {
  items: [dashboardTecnico],
};
const menuItemsContabilidade = {
  items: [filteredFinanceiro],
};

export {
  menuItemsAdmin,
  menuItemsTecnico,
  menuItemsGerencial,
  menuItemsContabilidade,
};
