import axios from "axios";

const api = axios.create({
  //baseURL: process.env.REACT_APP_API_URL,
  baseURL: "https://portal.netbrokers.com.br/api",
});
api.interceptors.request.use(async (config) => {
  const token = sessionStorage.getItem("token");

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export default api;
