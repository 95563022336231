import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Box";
import * as React from "react";

function LoadingCircle() {
  return (
    <Grid
      container
      sx={{
        display: "flex",
        justifyContent: "center",
        padding: 5,
        textAlign: "center",
      }}
    >
      <CircularProgress />
    </Grid>
  );
}

export default LoadingCircle;
