import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import PersonPinOutlinedIcon from "@mui/icons-material/PersonPinOutlined";
import { Check } from "../../node_modules/@mui/icons-material/index";
import SettingsIcon from "@mui/icons-material/Settings";
const icons = {
  AccountCircleOutlinedIcon,
  GroupsOutlinedIcon,
  ManageAccountsOutlinedIcon,
  PersonPinOutlinedIcon,
  SettingsIcon,
  Check
};

const gerencial = {
  id: "gerencial",
  title: "Gerencial",
  type: "group",
  children: [
    {
      id: "aprovacao-lancamento",
      title: "Aprovação de Lançamentos",
      type: "item",
      url: "/aprovacaoLancamentos",
      icon: icons.Check,
      breadcrumbs: false,
    },
  ],
};

export default gerencial;
