// material-ui
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';

 *
 */

// ==============================|| LOGO SVG ||============================== //
import logo from 'assets/images/logo.png';
const Logo = () => {
    return <img src={logo} alt="NetBrokers" width="300" />;
};

export default Logo;
