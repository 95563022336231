import { toast } from "react-toastify";
import moment from "../../node_modules/moment/moment";
import api from "../utils/config";
import StatusEnum from "components/enuns/TipoEventoLancamento";

export const useApi = () => ({
  signin: async (Username, Password) => {
    try {
      return await api.post("/Login/login", {
        Username,
        Password,
      });
    } catch (error) {
      if (error.response.data) {
        toast.error(error.response.data, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return error;
    }
  },
  getFuncionario: async () => {
    const response = await api.get("/Funcionario");

    return response;
  },
  postFuncionario: async (data) => {
    const response = await api.post("/Funcionario", data);

    return response;
  },
  getFuncionarioById: async (id) => {
    const response = await api.get(`/Funcionario/${id}`);

    return response;
  },

  putFuncionario: async (data) => {
    const response = await api.put("/Funcionario", data);

    return response;
  },

  deleteFuncionario: async (funcionarioId) => {
    const response = await api.delete(`/Funcionario/${funcionarioId}`);

    return response;
  },

  funcionarioPasswordReset: async (email) => {
    const response = await api.get(`/Funcionario/ResetPassword/${email}`);
  },
  funcionarioUpdateSenha: async (data) => {
    const response = await api.post(`/Funcionario/UpdateSenha`, data);
  },

  getManagers: async () => {
    const response = await api.get("/Funcionario/GetManagers");
    return response;
  },

  getCargos: async () => {
    const response = await api.get("/Cargo/GetCargos");
    return response;
  },
  getCargo: async (cargoId) => {
    const response = await api.get(`/Cargo/${cargoId}`);

    return response.data;
  },
  postCargo: async (data) => {
    const response = await api.post("/Cargo", data);

    return response;
  },
  putCargo: async (data) => {
    const response = await api.put("/Cargo", data);

    return response;
  },
  deleteCargo: async (cargoId) => {
    const response = await api.get(`/Cargo/${cargoId}`);

    return response.data;
  },

  getCargoByFuncionarioId: async (funcionarioId) => {
    const response = await api.get(`/CargoFuncionario/${funcionarioId}`);

    return response.data;
  },
  postCargoFuncionario: async (data) => {
    return await api.post("/CargoFuncionario", data);
  },
  putCargoFuncionario: async (data) => {
    const response = await api.put("/CargoFuncionario", data);

    return response;
  },
  deleteCargoFuncionario: async (cargoFuncionarioId) => {
    const response = await api.delete(
      `/CargoFuncionario/${cargoFuncionarioId}`
    );

    return response.data;
  },

  getCliente: async (uploadExterno) => {
    const response = await api.get("/Cliente", {
      params: {
        uploadExterno: uploadExterno,
      },
    });

    return response;
  },
  postCliente: async (
    clienteNome,
    clienteRazaoSocial,
    clienteCNPJ,
    clienteEndereco,
    clienteCEP,
    clienteAtivo = true
  ) => {
    const response = await api.post("/Cliente", {
      clienteNome,
      clienteRazaoSocial,
      clienteCNPJ,
      clienteEndereco,
      clienteCEP,
      clienteAtivo,
    });

    return response;
  },
  getClienteById: async (id) => {
    const response = await api.get(`/Cliente/${id}`);

    return response;
  },
  getClienteByFuncionarioId: async (id) => {
    const response = await api.get(`/Cliente/GetByFuncionarioId/${id}`);

    return response;
  },

  putCliente: async (
    clienteId,
    clienteNome,
    clienteRazaoSocial,
    clienteCNPJ,
    clienteEndereco,
    clienteCEP,
    clienteAtivo = true
  ) => {
    const response = await api.put("/Cliente", {
      clienteId,
      clienteNome,
      clienteRazaoSocial,
      clienteCNPJ,
      clienteEndereco,
      clienteCEP,
      clienteAtivo,
    });

    return response;
  },

  deleteCliente: async (clienteId) => {
    const response = await api.delete(`/Cliente/${clienteId}`);

    return response;
  },
  getCategoriaContasPagar: async () => {
    const response = await api.get("/CategoriaContasPagar");

    return response;
  },

  postCategoriaContasPagar: async (
    categoriaContasPagarNome,
    categoriaContasPagarAtivo = true
  ) => {
    const response = await api.post("/CategoriaContasPagar", {
      categoriaContasPagarNome,
      categoriaContasPagarAtivo,
    });

    return response;
  },
  getCategoriaContasPagarById: async (id) => {
    const response = await api.get(`/CategoriaContasPagar/${id}`);

    return response;
  },
  putCategoriaContasPagar: async (
    categoriaContasPagarId,
    categoriaContasPagarNome,
    categoriaContasPagarAtivo = true
  ) => {
    const response = await api.put("/CategoriaContasPagar", {
      categoriaContasPagarId,
      categoriaContasPagarNome,
      categoriaContasPagarAtivo,
    });

    return response;
  },
  deleteCategoriaContasPagar: async (categoriaContasPagarId) => {
    const response = await api.delete(
      `/CategoriaContasPagar/${categoriaContasPagarId}`
    );

    return response;
  },
  //////////////////////////////////////////////////
  getCategoriaContasReceber: async () => {
    const response = await api.get("/CategoriaContasReceber");

    return response;
  },
  postCategoriaContasReceber: async (
    categoriaContasReceberNome,
    categoriaContasReceberAtivo = true
  ) => {
    const response = await api.post("/CategoriaContasReceber", {
      categoriaContasReceberNome,
      categoriaContasReceberAtivo,
    });

    return response;
  },
  getCategoriaContasReceberById: async (id) => {
    const response = await api.get(`/CategoriaContasReceber/${id}`);

    return response;
  },
  putCategoriaContasReceber: async (
    categoriaContasReceberId,
    categoriaContasReceberNome,
    categoriaContasReceberAtivo = true
  ) => {
    const response = await api.put("/CategoriaContasReceber", {
      categoriaContasReceberId,
      categoriaContasReceberNome,
      categoriaContasReceberAtivo,
    });

    return response;
  },
  deleteCategoriaContasReceber: async (categoriaContasReceberId) => {
    const response = await api.delete(
      `/CategoriaContasReceber/${categoriaContasReceberId}`
    );

    return response;
  },
  getGestor: async () => {
    const response = await api.get("/Gestor");

    return response;
  },
  postGestor: async ({
    gestorClienteId,
    gestorNome,
    gestorEmail,
    gestorDDD,
    gestorCelular,
    gestorClienteNome,
  }) => {
    const response = await api.post("/Gestor", {
      gestorClienteId,
      gestorNome,
      gestorEmail,
      gestorDDD,
      gestorCelular,
      gestorAtivo: true,
      gestorClienteNome,
    });

    return response;
  },
  putGestor: async ({
    gestorId,
    gestorClienteId,
    gestorNome,
    gestorEmail,
    gestorDDD,
    gestorCelular,
    gestorClienteNome,
  }) => {
    const response = await api.put("/Gestor", {
      gestorId,
      gestorClienteId,
      gestorNome,
      gestorEmail,
      gestorDDD,
      gestorCelular,
      gestorAtivo: true,
      gestorClienteNome,
    });

    return response;
  },
  deleteGestor: async (gestorId) => {
    const response = await api.delete(`/Gestor/${gestorId}`);

    return response;
  },
  getGestorById: async (id) => {
    const response = await api.get(`/Gestor/${id}`);

    return response;
  },
  getGestorByCliente: async (id) => {
    const response = await api.get(`/Gestor/byCliente/${id}`);

    return response;
  },
  getGestorByEmail: async (email) => {
    const response = await api.get(`/Gestor/${email}`);

    return response;
  },
  getProjeto: async () => {
    const response = await api.get("/Projeto");

    return response;
  },
  getProjetoAndClienteByFuncionarioId: async (id) => {
    const response = await api.get(
      `/Projeto/getProjetoAndClienteByFuncionarioId/${id}`
    );
    return response;
  },
  postProjeto: async (projetoNome, projetoGeraFatura) => {
    const response = await api.post("/Projeto", {
      projetoNome,
      projetoGeraFatura,
      projetoAtivo: true,
    });

    return response;
  },
  putProjeto: async (projetoId, projetoNome, projetoGeraFatura) => {
    const response = await api.put("/Projeto", {
      projetoId,
      projetoNome,
      projetoGeraFatura,
      projetoAtivo: true,
    });

    return response;
  },
  deleteProjeto: async (projetoId) => {
    const response = await api.delete(`/Projeto/${projetoId}`);

    return response;
  },
  getProjetoById: async (projetoId) => {
    const response = await api.get(`/Projeto/byId/${projetoId}`);

    return response;
  },
  getProjetoByName: async (projetoNome) => {
    const response = await api.get(`/Projeto/byName/${projetoNome}`);

    return response;
  },
  getProjetoCompleto: async (projetoId) => {
    const response = await api.get(`/ProjetoCompleto/${projetoId}`);
    return response;
  },
  getProjetoFuncionariobyCliente: async (clienteId) => {
    const response = await api.get(`/Projeto/byClienteId/${clienteId}`);
    return response;
  },
  postProjetoCompleto: async (data) => {
    let info = { ...data };

    info = { ...info, projetoClientes: [...info.clientes] };
    info.projetoAtivo = true;
    info.projetoClientes.forEach((cliente, index) => {
      info.projetoClientes[index].projetoClienteFuncionarios =
        cliente.funcionarios ?? [];

      delete info.projetoClientes[index].funcionarios;
    });

    delete info.clientes;
    delete info.projetoClientes.projetoId;

    info.projetoClientes.forEach((_, index) => {
      info.projetoClientes[index].projetoClienteId = null;
    });

    info.projetoClientes.forEach((cliente) => {
      delete cliente.projetoClienteId;
      delete cliente.projetoId;
      delete cliente.projetoClienteDataCadastro;
    });

    const response = await api.post(`/ProjetoCompleto`, info);
    return response;
  },
  putProjetoCompleto: async (data) => {
    let info = { ...data };
    info = { ...info, projetoClientes: [...info.clientes] };
    info.projetoAtivo = true;
    info.projetoClientes.forEach((cliente, index) => {
      info.projetoClientes[index].projetoClienteFuncionarios =
        cliente.funcionarios ?? [];

      delete info.projetoClientes[index].funcionarios;
    });
    delete info.clientes;
    delete info.projetoClientes.projetoId;
    info.projetoClientes.forEach((cliente) => {
      if (!cliente.projetoClienteId) {
        delete cliente.projetoClienteId;
        delete cliente.projetoId;
        delete cliente.projetoClienteDataCadastro;
      }
    });
    const response = await api.put(`/ProjetoCompleto`, info);
    return response;
  },
  getProjetoCliente: async (id) => {
    const response = await api.get(`/ProjetoCliente/byId/${id}`);

    return response;
  },
  deleteProjetoClienteId: async (projetoClienteId) => {
    const response = await api.delete(`/ProjetoCliente/${projetoClienteId}`);
    return response;
  },
  getProjetoByClienteAndFuncionarioId: async (
    clienteId,
    funcionarioId,
    uploadExterno
  ) => {
    const response = await api.get(
      `/Projeto/byClienteAndFuncionarioId/${clienteId}/${funcionarioId}`,
      {
        params: {
          uploadExterno: uploadExterno,
        },
      }
    );
    return response;
  },
  getProjetoClienteFuncionarioById: async (funcionarioId) => {
    const response = await api.get(
      `api/ProjetoClienteFuncionario/byId/${funcionarioId}`
    );
  },
  deleteProjetoClienteFuncionarioById: async (
    projetoClienteId,
    funcionarioId
  ) => {
    const response = await api.delete(
      `/ProjetoClienteFuncionario/${projetoClienteId}/funcionario/${funcionarioId}`
    );
    return response;
  },
  createLancamento: async (lancamento) => {
    const response = await api.post(`/Lancamento`, lancamento);

    return response;
  },
  updateLancamento: async (lancamento) => {
    const response = await api.put(`/Lancamento`, lancamento);

    return response;
  },
  getLancamento: async (dtInicio, dtFim) => {
    const response = await api.get(`/Lancamento/${dtInicio}/${dtFim}`);
    var statues = StatusEnum;
    const formatDate = (data) => {
      if (!data) {
        return null;
      }
      return moment(data).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
    };
    const data = response.data.map((item) => {
      return {
        ...item,
        start: new Date(item.lancamentoEntrada1),
        end: item.lancamentoSaida2
          ? new Date(item.lancamentoSaida2)
          : new Date(item.lancamentoSaida1),
        event_id: item.lancamentoId,
        title: (
          <div>
            <div>
              {item.clienteNome} - {item.projetoNome}
            </div>
            <div>
              {item.status} - {item.responsavelEvento}
            </div>
            {item.motivo && <div>{item.motivo}</div>}
          </div>
        ),
        description: item.lancamentoDetalhe,
        shouldUpdate: true,
        lancamentoData: formatDate(item.lancamentoData),
        lancamentoEntrada1: formatDate(item.lancamentoEntrada1),
        lancamentoSaida1: formatDate(item.lancamentoSaida1),
        lancamentoEntrada2: formatDate(item.lancamentoEntrada2),
        lancamentoSaida2: formatDate(item.lancamentoSaida2),
        color:
          Object.values(statues).find((s) => s.label == item.status)?.color ||
          statues.REGISTRADO.color,
        editable:
          item.status != statues.APROVADO.label &&
          item.status != statues.FINALIZADO.label,
        deletable:
          item.status != statues.APROVADO.label &&
          item.status != statues.FINALIZADO.label,
      };
    });
    return data;
  },
  getFuncionarioByClienteAndProjetoId: async (clienteId, projetoId) => {
    const params = {
      clienteId,
      projetoId,
    };

    const response = await api.get(`/Funcionario/byClienteAndProjetoId`, {
      params,
    });
    return response;
  },
  deleteLancamento: async (lancamentoId) => {
    const response = await api.delete(`/Lancamento/${lancamentoId}`);

    return response;
  },
  getRelatorio: async () => {
    const response = await api.get(`/lancamentos`);
  },
  getGenerateExcelHoras: async (
    clienteId,
    projetoId,
    funcionarioId,
    periodoInicial,
    periodoFinal
  ) => {
    const response = await api.get(`/Relatorios/generateExcelHoras`, {
      params: {
        clienteId,
        projetoId,
        funcionarioId,
        periodoInicial,
        periodoFinal,
      },
      responseType: "blob",
    });
    return response;
  },
  getDownloadTemplateImport: async () => {
    const response = await api.get(`/Relatorios/DownloadTemplateImport`, {
      responseType: "blob",
    });
    return response;
  },
  postUploadTimesheetExterno: async (
    clienteId,
    projetoId,
    dataCompetencia,
    file,
    canOverwrite = false,
    cargoFuncionarioId
  ) => {
    const formData = new FormData();

    formData.append("clienteId", clienteId);
    formData.append("projetoId", projetoId);
    formData.append("dataCompetencia", dataCompetencia);
    formData.append("file", file);
    formData.append("cargoFuncionarioId", cargoFuncionarioId);
    formData.append("canOverwrite", canOverwrite);
    const response = await api.post(`/Lancamento/uploadExcel`, formData);
    return response;
  },
  getRelatoriosLancamento: async (
    funcionarioId,
    clienteId,
    projetoId,
    periodoInicial,
    periodoFinal
  ) => {
    const response = await api.get(`/relatorios/lancamentos`, {
      params: {
        funcionarioId,
        clienteId,
        projetoId,
        periodoInicial,
        periodoFinal,
      },
    });

    return response;
  },
  getRelatoriosHoras: async (
    funcionarioId,
    projetoId,
    clienteId,
    periodoInicial,
    periodoFinal
  ) => {
    const response = await api.get(`/relatorios/horas`, {
      params: {
        funcionarioId,
        projetoId,
        clienteId,
        periodoInicial,
        periodoFinal,
      },
    });
    return response;
  },

  getRelatoriosPdf: async (
    clienteId,
    funcionarioId,
    projetoId,
    periodoInicial,
    periodoFinal
  ) => {
    const response = await api.get(`/Relatorios/generatePdfHoras`, {
      params: {
        clienteId,
        funcionarioId,
        projetoId,
        periodoInicial,
        periodoFinal,
      },
    });

    return response;
  },

  getConfiguration: async () => {
    const response = await api.get("/Configuracao");
    return response;
  },
  updateConfiguration: async (item) => {
    const response = await api.put("/Configuracao", { ...item });
    return response.status === 200;
  },

  getLancamentoEstado: async (
    funcionarioId,
    clienteId,
    projetoId,
    periodoInicial,
    periodoFinal,
    statusLancamentoId
  ) => {
    const response = await api.get(`/lancamento/lancamentosEstado`, {
      params: {
        funcionarioId,
        clienteId,
        projetoId,
        periodoInicial,
        periodoFinal,
        statusLancamentoId,
      },
    });

    return response;
  },
  getTipoEventoLancamento: async () => {
    const response = await api.get("Lancamento/TipoEvento");
    return response;
  },

  AtualizarStatusLancamento: async (data) => {
    const response = await api.put(
      "/Lancamento/AtualizarStatusLancamento",
      data
    );

    return response;
  },
  getCompetenciasAbertas: async () => {
    const response = await api.get("Competencia/Abertas");
    return response;
  },
  getCompetencias: async () => {
    const response = await api.get("Competencia");
    return response;
  },

  postCompetencia: async (data) => {
    const response = await api.post("/Competencia", data);

    return response;
  },

  putCompetencia: async (data) => {
    const response = await api.put("/Competencia", data);

    return response;
  },

  deleteCompetencia: async (competenciaId) => {
    const response = await api.delete(`/Competencia/${competenciaId}`);

    return response;
  },

  getCompetenciaById: async (id) => {
    const response = await api.get(`/Competencia/${id}`);

    return response;
  },

  getContasPagar: async (
    funcionarioId,
    periodoCadastroInicial,
    periodoCadastroFinal,
    periodoVencimentoInicial,
    periodoVencimentoFinal,
    Status,
    competenciaCodigo
  ) => {
    const response = await api.get(`/ContasPagar/ContasPagar`, {
      params: {
        funcionarioId,
        periodoVencimentoInicial,
        periodoVencimentoFinal,
        periodoCadastroInicial,
        periodoCadastroFinal,
        Status,
        competenciaCodigo,
      },
    });

    return response;
  },
  iniciarContasPagar: async (data) => {
    const response = await api.put("/ContasPagar/IniciarContasPagar", data);

    return response;
  },
  agendarPagamento: async (data) => {
    const response = await api.post("/ContasPagar/AgendarPagamento", data);
    return response;
  },
  atualizarContasPagar: async (data) => {
    const response = await api.put("/ContasPagar/AtualizarContasPagar", data);
    return response;
  },

  uploadComprovante: async (formData) => {
    const response = await api.post(
      "/ContasPagar/UploadComprovante",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  },

  getComprovante: async (id) => {
    const response = await api.get(
      `/ContasPagar/GetComprovante?comprovanteId=${id}`,
      {
        responseType: "blob",
      }
    );
    return response;
  },

  getNFSe: async (id) => {
    const response = await api.get(`/ContasPagar/GetNFSeById?nfseId=${id}`, {
      responseType: "blob",
    });
    return response;
  },

  getContasPagarFuncionario: async () => {
    const response = await api.get(`/Lancamento/GetContasPagarFuncionario`);

    return response;
  },
  uploadNFSe: async (formData) => {
    const response = await api.post("/Lancamento/UploadNfse", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  },

  getNFSeFuncionario: async (id) => {
    const response = await api.get(`/Lancamento/GetNFSeById?nfseId=${id}`, {
      responseType: "blob",
    });
    return response;
  },
  getComprovanteFuncionario: async (id) => {
    const response = await api.get(
      `/Lancamento/GetComprovanteById?comprovanteId=${id}`,
      {
        responseType: "blob",
      }
    );
    return response;
  },

  getContasPagarItem: async (contasPagarId) => {
    const response = await api.get(`/ContasPagarItem/${contasPagarId}`);
    return response;
  },

  getCategoriaContasPagarItem: async () => {
    const response = await api.get("/CategoriaContasPagarItem");

    return response;
  },

  postCategoriaContasPagarItem: async (
    categoriaContasPagarItemNome,
    categoriaContasPagarItemAtivo = true
  ) => {
    const response = await api.post("/CategoriaContasPagarItem", {
      categoriaContasPagarItemNome,
      categoriaContasPagarItemAtivo,
    });

    return response;
  },
  getCategoriaContasPagarItemById: async (id) => {
    const response = await api.get(`/CategoriaContasPagarItem/${id}`);

    return response;
  },
  putCategoriaContasPagarItem: async (
    categoriaContasPagarItemId,
    categoriaContasPagarItemNome,
    categoriaContasPagarItemAtivo = true
  ) => {
    const response = await api.put("/CategoriaContasPagarItem", {
      categoriaContasPagarItemId,
      categoriaContasPagarItemNome,
      categoriaContasPagarItemAtivo,
    });

    return response;
  },
  deleteCategoriaContasPagarItem: async (categoriaContasPagarItemId) => {
    const response = await api.delete(
      `/CategoriaContasPagarItem/${categoriaContasPagarItemId}`
    );

    return response;
  },
  postContasPagar: async (data) => {
    const response = await api.post("/ContasPagar", data);

    return response;
  },
  postCriaNovoContasPagar: async (data) => {
    const response = await api.post("/ContasPagar/CriaNovoContasPagar", data);
    return response;
  },

  postContasPagarItem: async (data) => {
    const response = await api.post("/ContasPagarItem", data);

    return response;
  },
  putContasPagarItem: async (data) => {
    const response = await api.put("/ContasPagarItem", data);

    return response;
  },
  deleteContasPagarItem: async (contasPagarItemId) => {
    const response = await api.delete(`/ContasPagarItem/${contasPagarItemId}`);

    return response;
  },
});

// axios interceptor for error 500 to return generic error message

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 400) {
      return Promise.reject(error);
    } else if (error?.response?.status === 401) {
      window.location.href = "login";
      sessionStorage.clear();
      return;
    } else if (error?.response?.status === 409) {
      return Promise.reject(error);
    } else if (error?.response?.status === 500) {
      toast.error("Erro de comunicação com o servidor", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return Promise.reject(error);
    } else if (error?.response?.data === "Usuário ou senha inválidos") {
      return Promise.reject(error);
    } else {
      toast.error("Erro de comunicação com o servidor");
      return Promise.reject(error);
    }
  }
);
