import React from "react";
import { useState, useEffect } from "react";
import { useApi } from "hooks/useApi";
import { useParams, useNavigate } from "react-router-dom";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { Formik, Form, Field, ErrorMessage } from "formik";
import NumericFormat from "react-number-format";
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  FormControl,
  Grid,
  Link,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  Paper,
  Modal,
  Tooltip,
} from "@mui/material";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";

import * as Yup from "yup";

import Loader from "components/Loader";
import ModalSucessMessage from "components/modalSucess";
import { ToastContainer, toast } from "react-toastify";

const CriarConfig = () => {
  //regex para campos de número
  const onlyNumbers = /^\d+$/;

  //regex para campos em branco
  const blankSpaces = /^\S+$/;

  //interação com a api
  const api = useApi();
  const { id } = useParams();
  const [cliente, setCliente] = useState({
    clienteNome: "",
    clienteRazaoSocial: "",
    clienteCNPJ: "",
    clienteEndereco: "",
    clienteCEP: "",
    uploadExterno: false,
  });

  const navigate = useNavigate();
  const GetData = async () => {
    const response = await api.getClienteById(id);
    const info = { ...response.data };
    setCliente(info);
  };

  // Loader
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (id) {
      GetData();
    }
  }, []);

  // Modal
  const [open, setOpen] = useState(false);
  const handleClose = () => navigate(`/cliente/`);
  const handleOpen = () => setOpen(true);

  return (
    <>
      <ModalSucessMessage open={open} handleClose={handleClose} />
      {isLoading ? <Loader /> : null}
      <Grid container alignItems="center" justifyContent="space-between">
        <Typography variant="h4">Configuração Cadastro</Typography>
        <Button
          onClick={() => {
            navigate(`/cliente/`);
          }}
        >
          <Tooltip title="Voltar">
            <Fab size="small" color="primary" variant="extended">
              <ArrowBackIcon />
              Voltar
            </Fab>
          </Tooltip>
        </Button>
      </Grid>
      <Formik
        validationSchema={Yup.object().shape({
          clienteNome: Yup.string().max(255).required("Nome é obrigatório"),
          clienteRazaoSocial: Yup.string()
            .max(255)
            .required("Razão social é obrigatória"),
          clienteCNPJ: Yup.string()
            .matches(blankSpaces, "O CNPJ é inválido")
            .min(14, "CNPJ deve ser preenchido")
            .required("CNPJ é obrigatório"),
          clienteEndereco: Yup.string()
            .max(255)
            .required("Endereço é obrigatório"),
          clienteCEP: Yup.string()
            // .matches(onlyNumbers, "O número é inválido")
            .matches(blankSpaces, "O CEP é inválido")
            .required("CEP é obrigatório"),
        })}
        enableReinitialize={true}
        initialValues={cliente}
        onSubmit={async (values, { setSubmitting }) => {
          setIsLoading(true);

          try {
            const formattedClienteCNPJ = values.clienteCNPJ.replace(
              /[^\d]+/g,
              ""
            );

            const formattedClienteCEP = values.clienteCEP.replace("-", "");

            if (id) {
              const response = await api.putCliente(
                id,
                values.clienteNome,
                values.clienteRazaoSocial,
                formattedClienteCNPJ,
                values.clienteEndereco,
                formattedClienteCEP
                //values.uploadExterno
              );
              setIsLoading(false);
              handleOpen();
              return;
            }

            const response = await api.postCliente(
              values.clienteNome,
              values.clienteRazaoSocial,
              formattedClienteCNPJ,
              values.clienteEndereco,
              formattedClienteCEP
              //values.uploadExterno
            );
            setIsLoading(false);
            handleOpen();
          } catch (err) {
            if (err.response.data) {
              toast.error(err.response.data, {
                position: toast.POSITION.TOP_RIGHT,
              });
            } else {
              toast.error("Erro na solicitação!", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
            setIsLoading(false);
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => {
          return (
            <Paper style={{ padding: "30px" }}>
              <form noValidate onSubmit={handleSubmit}>
                <Grid
                  alignItems="center"
                  container
                  rowSpacing={4}
                  columnSpacing={10}
                  spacing={1}
                >
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="Nome-Cliente">Nome</InputLabel>
                      <OutlinedInput
                        id="Nome-Cliente"
                        placeholder="Nome"
                        value={values.clienteNome}
                        name="clienteNome"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        fullWidth
                      />
                      <FormHelperText
                        error
                        sx={{
                          opacity:
                            touched.clienteNome && errors.clienteNome ? 1 : 0,
                        }}
                      >
                        {errors.clienteNome}.
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="RazaoSocial-Cliente">
                        Razão Social
                      </InputLabel>
                      <OutlinedInput
                        id="RazaoSocial-Cliente"
                        value={values.clienteRazaoSocial}
                        placeholder="Razão Social"
                        name="clienteRazaoSocial"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        fullWidth
                      />
                      <FormHelperText
                        error
                        sx={{
                          opacity:
                            touched.clienteRazaoSocial &&
                            errors.clienteRazaoSocial
                              ? 1
                              : 0,
                        }}
                      >
                        {errors.clienteRazaoSocial}.
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="CNPJ-Cliente">CNPJ</InputLabel>
                      <NumericFormat
                        customInput={OutlinedInput}
                        value={values.clienteCNPJ}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name={`clienteCNPJ`}
                        format="##.###.###/####-##"
                        fullWidth
                      />
                      <FormHelperText
                        error
                        sx={{
                          opacity:
                            touched.clienteCNPJ && errors.clienteCNPJ ? 1 : 0,
                        }}
                      >
                        {errors.clienteCNPJ}.
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="Cliente-Endereco">
                        Endereço
                      </InputLabel>
                      <OutlinedInput
                        id="Cliente-Endereco"
                        value={values.clienteEndereco}
                        placeholder="Endereço"
                        name="clienteEndereco"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        fullWidth
                      />
                      <FormHelperText
                        error
                        sx={{
                          opacity:
                            touched.clienteEndereco && errors.clienteEndereco
                              ? 1
                              : 0,
                        }}
                      >
                        {errors.clienteEndereco}.
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="Cliente-Cep">CEP</InputLabel>
                      <NumericFormat
                        customInput={OutlinedInput}
                        value={values.clienteCEP}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name={`clienteCEP`}
                        format="#####-###"
                        fullWidth
                      />
                      <FormHelperText
                        error
                        sx={{
                          opacity:
                            touched.clienteCEP && errors.clienteCEP ? 1 : 0,
                        }}
                      >
                        {errors.clienteCEP}.
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  item
                  spacing={1}
                  xs={12}
                  sm={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: 2,
                  }}
                >
                  <Button type="submit" disabled={isSubmitting}>
                    <Fab size="small" color="primary" variant="extended">
                      <CheckOutlinedIcon />
                      Salvar
                    </Fab>
                  </Button>
                </Grid>
              </form>
            </Paper>
          );
        }}
      </Formik>
    </>
  );
};

export default CriarConfig;
