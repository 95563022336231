// third-party
import { combineReducers } from "redux";

// project import
import menu from "./menu";
import { configuration } from "./configuration";

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({ menu, configuration });

export default reducers;
