import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
};

export default function ModalSucessMessage({ open, handleClose }) {
    return (
        <div>
            {/* <Button onClick={handleOpen}>Open modal</Button> */}
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <CheckCircleOutlineIcon color="success" sx={{ width: '70px', height: '70px' }} />
                    <Typography id="modal-modal-description" fontSize="1.1rem">
                        A operação foi concluída com sucesso
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
}
