const StatusEnum = Object.freeze({
    REGISTRADO: { id: 1, label: 'Registrado', colorMaterial: "primary", color: "#096dd9" },
    EDITADO: { id: 2, label: 'Editado', colorMaterial: "primary[700]", color: "#0050b3" },
    APROVADO: { id: 3, label: 'Aprovado', colorMaterial: "success.main", color: "#52c41a" },
    CORRIGIDO: { id: 4, label: 'Corrigido', colorMaterial: "primary.900", color: "#002766" },
    REPROVADO: { id: 5, label: 'Reprovado', colorMaterial: "error.main", color: "#f5222d" },
    FINALIZADO: { id: 6, label: 'Finalizado', colorMaterial: "warning.main", color: "#faad14" },
});

export default StatusEnum;
