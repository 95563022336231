//toast global para envio de notificações na tela 

import React from 'react';
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer, toast } from 'react-toastify';


function Notification(){

  return (
      <ToastContainer />
    
  );
}

export default Notification;
