import { Navigate, useLocation } from "react-router-dom";
import MainRoutes from "routes/MainRoutes";
import jwt_decode from "jwt-decode";

export function RequireAuth({ children }) {
  const token = sessionStorage.getItem("token");
  const role = token ? jwt_decode(token).Role : null;

  let location = useLocation();

  const rota = MainRoutes.children.find((_item) => {
    return (
      _item?.path.replace(":id", "").toUpperCase() ===
      location.pathname.replace(/^\/|\d+/g, "").toUpperCase()
    );
  });

  if (!rota || !rota.roles) return children;

  const hasPermission = rota?.roles?.includes(role);

  if (!hasPermission) {
    return <Navigate to="/unauthorized" state={{ from: location }} replace />;
  }

  return children;
}
