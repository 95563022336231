export function configuration(state = [], action) {
  switch (action.type) {
    case "ADD_CONFIG":
      return action.payload;

    case "CLEAR_CONFIG":
      return [];

    default:
      return state;
  }
}
