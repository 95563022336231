// assets
import { DashboardOutlined } from "@ant-design/icons";

import HouseOutlinedIcon from "@mui/icons-material/HouseOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";

import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ListAltIcon from "@mui/icons-material/ListAlt";

import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";


// icons
const icons = {
  DashboardOutlined,
  HouseOutlinedIcon,
  CalendarMonthOutlinedIcon,
  PaidOutlinedIcon,
  RequestQuoteOutlinedIcon,
  NoteAltOutlinedIcon,
  ListAltIcon,
  AttachMoneyIcon
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboardTecnico = {
  id: "navegacao",
  title: "Navegação",
  type: "group",
  children: [
    {
      id: "inicio",
      title: "Início",
      type: "item",
      url: "/",
      icon: icons.HouseOutlinedIcon,
      breadcrumbs: false,
    },
    {
      id: "lancamento",
      title: "Lançamento",
      type: "item",
      url: "/lancamento",
      icon: icons.CalendarMonthOutlinedIcon,
      breadcrumbs: false,
    },
    {
      id: "relatorio",
      title: "Relatório",
      type: "item",
      url: "/relatorio",
      icon: icons.ListAltIcon,
      breadcrumbs: false,
    },
    {
      id: "fechamento",
      title: "Fechamento",
      type: "item",
      url: "/fechamento",
      icon: icons.AttachMoneyIcon,
      breadcrumbs: false,
    },
  ],
};

export default dashboardTecnico;
